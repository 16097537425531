/* eslint-disable */
import { AnimationClip } from 'three';
import {KeyframeTrack} from "three/src/animation/KeyframeTrack";

// The Data would be loaded via JSON.
export function AnimationLoader(animations: any[]) {
  let animationClip: AnimationClip | null = null;
  animations.forEach((animation) => {
    const tracks = animation.tracks.filter((track: KeyframeTrack) => {
      const name = track.name.toLowerCase();
      return !name.includes('.position') && !name.includes('shoulder');
    });
    if (tracks.length > 0) {
      animation.tracks = tracks;
      animationClip = AnimationClip.parse(animation);
    }
  });
  return animationClip;
}
